// Importa Firebase
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

// Tu configuración de Firebase
const firebaseConfig = {
    apiKey: "AIzaSyB6GqvA0YYgmZn9m90bPneEbAEZiiFKOzE",
    authDomain: "wscrm-evermit.firebaseapp.com",
    databaseURL: "https://wscrm-evermit-default-rtdb.firebaseio.com",
    projectId: "wscrm-evermit",
    storageBucket: "wscrm-evermit.appspot.com",
    messagingSenderId: "410095792469",
    appId: "1:410095792469:web:eb839bfdc48f2bdd71ffdc"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


export {db} ;