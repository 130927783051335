import React, { useEffect, useState,useRef } from "react";
import { db } from "../firebase-config"; 
import { collection, onSnapshot } from "firebase/firestore";


function ChatContainer({ numero }) {
  const [msg, setMsg] = useState([]);
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false); // Nuevo estado para manejar si se está enviando el mensaje
  const chatBodyRef = useRef(null);



  const sendMessage = async (e) => {
    e.preventDefault(); // Prevenir el comportamiento por defecto del formulario
    if (!message.trim()) return; // Evitar enviar mensajes vacíos
    setIsSending(true); // Deshabilitar el input y el botón antes de enviar el mensaje
    const encodedData = `txtMsg=${encodeURIComponent(
      message
    )}&txtTo=${encodeURIComponent(numero)}`;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodedData,
      // Codificar el mensaje para el envío
    };
    try {
      const response = await fetch("./inputManager.php", requestOptions);
      const data = await response.text(); // O .json(), dependiendo de la respuesta de tu backend
      console.log(data); // Manejar la respuesta aquí
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
    }
    setMessage(""); // Limpiar el input después de enviar
    setIsSending(false); // Habilitar el input y el botón después de enviar
  };

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [msg]);
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, `msg/${numero}/messages`),
      (snapshot) => {
        const itemsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMsg(itemsList);
      }
    );

    return () => unsubscribe();
  }, [numero]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage(e);
    }
  };

  return (
    <div className="contenedorChat">
      <div className="chat__container">
        <div className="chat__header">
          <p>{numero}</p>
        </div>
        <div className="chat__body" ref={chatBodyRef}>
          {msg.map((msge) => (
            <div
              key={msge.id}
              className={`chat__mensaje chat__${
                msge.owner === "commerce"
                  ? "enviado scale-in-hor-right"
                  : "recibido scale-in-hor-left"
              }`}
            >
              <span className="chat__phone">
                {msge.owner === "commerce" ? "Tú" : numero}
              </span>
              <p>
                {msge.owner === "commerce" ? msge.text.body : msge.messageText}
                {msge.messageType === "sticker" ? '[sticker]': ''}
              </p>
            </div>
          ))}
          <div id="chat__bottom"></div>
        </div>
        <div className="">
          <form onSubmit={sendMessage} className="chat__footer">
            <input
              id="msgInput"
              className="chat__input"
              type="text"
              placeholder="Escribe un mensaje"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              disabled={isSending} // Usar el estado para deshabilitar el input
            />
            <button
              className="chat__btn__send"
              type="submit"
              disabled={isSending}
              onClick={sendMessage}
            >
              Enviar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChatContainer;
