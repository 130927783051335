import React, { useState, useEffect } from 'react';

function ClientContainer(item) {
  // Estados para cada valor
  console.log(item)
  const [id, setId] = useState(item.numero?.[0]?.id ?? '');
  const [nombre, setNombre] = useState(item.numero?.[0]?.nombre ?? '');
  const [apellido, setApellido] = useState(item.numero?.[0]?.Apellido ?? '');
  const [telefono, setTelefono] = useState(item.numero?.[0]?.phone ?? '');
  const [email, setEmail] = useState(item.numero?.[0]?.email ?? '');
  const [direccion, setDireccion] = useState(item.numero?.[0]?.direccion ?? '');
  const [ciudad, setCiudad] = useState(item.numero?.[0]?.ciudad ?? '');
  const [estado, setEstado] = useState(item.numero?.[0]?.estado ?? '');
  const [cp, setCp] = useState(item.numero?.[0]?.cp ?? '');
  const [pais, setPais] = useState(item.numero?.[0]?.pais ?? '');
  const [empresa, setEmpresa] = useState(item.numero?.[0]?.empresa ?? '');
  const [cargo, setCargo] = useState(item.numero?.[0]?.cargo ?? '');
  const [waId, setWaId] = useState(item.numero?.[0]?.waId ?? '');
  const [phoneNumberId, setPhoneNumberId] = useState(item.numero?.[0]?.phoneNumberId ?? '');
  useEffect(() => {
    setId(item.numero?.[0]?.id ?? '');
    setNombre(item.numero?.[0]?.nombre ?? '');
    setApellido(item.numero?.[0]?.Apellido ?? '');
    setTelefono(item.numero?.[0]?.phone ?? '');
    setEmail(item.numero?.[0]?.email ?? '');
    setDireccion(item.numero?.[0]?.direccion ?? '');
    setCiudad(item.numero?.[0]?.ciudad ?? '');
    setEstado(item.numero?.[0]?.estado ?? '');
    setCp(item.numero?.[0]?.cp ?? '');
    setPais(item.numero?.[0]?.pais ?? '');
    setEmpresa(item.numero?.[0]?.empresa ?? '');
    setCargo(item.numero?.[0]?.cargo ?? '');
    setWaId(item.numero?.[0]?.waId ?? '');
    setPhoneNumberId(item.numero?.[0]?.phoneNumberId ?? '');
  }, [item]); // Dependencias del efecto

  return (
    <div className="client-container">
        
        <label htmlFor="id">ID</label>
        <input type="text" id="id" value={id} onChange={(e) => setId(e.target.value)} placeholder="Ingresa el ID" />
        <label htmlFor="nombre">Nombre</label>
        <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} placeholder="Nombre" />
        <label htmlFor="apellido">Apellido</label>
        <input type="text" value={apellido} onChange={(e) => setApellido(e.target.value)} placeholder="Apellido" />
        <label htmlFor="telefono">Teléfono</label>
        <input type="text" value={telefono} onChange={(e) => setTelefono(e.target.value)} placeholder="Teléfono" />
        <label htmlFor="email">Email</label>
        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
        <label htmlFor="direccion">Dirección</label>
        <input type="text" value={direccion} onChange={(e) => setDireccion(e.target.value)} placeholder="Dirección" />
        <label htmlFor="ciudad">Ciudad</label>
        <input type="text" value={ciudad} onChange={(e) => setCiudad(e.target.value)} placeholder="Ciudad" />
        <label htmlFor="estado">Estado</label>
        <input type="text" value={estado} onChange={(e) => setEstado(e.target.value)} placeholder="Estado" />
        <label htmlFor="cp">Código Postal</label>
        <input type="text" value={cp} onChange={(e) => setCp(e.target.value)} placeholder="Código Postal" />
        <label htmlFor="pais">País</label>
        <input type="text" value={pais} onChange={(e) => setPais(e.target.value)} placeholder="País" />
        <label htmlFor="empresa">Empresa</label>
        <input type="text" value={empresa} onChange={(e) => setEmpresa(e.target.value)} placeholder="Empresa" />
        <label htmlFor="cargo">Cargo</label>
        <input type="text" value={cargo} onChange={(e) => setCargo(e.target.value)} placeholder="Cargo" />
        <label htmlFor="waId">ID de WhatsApp</label>
        <input type="text" value={waId} onChange={(e) => setWaId(e.target.value)} placeholder="ID de WhatsApp" />
        <label htmlFor="phoneNumberId">ID de Teléfono</label>
        <input type="text" value={phoneNumberId} onChange={(e) => setPhoneNumberId(e.target.value)} placeholder="ID de Teléfono" />
        <button onClick={() => console.log({ id, nombre, apellido, telefono, email, direccion, ciudad, estado, cp, pais, empresa, cargo, waId, phoneNumberId })}>Guardar</button>
      

        
        
        
        
        
        
        
        
        
        
        
        
        
        


        
    </div>
  );
}

export default ClientContainer;