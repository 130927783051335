import React, { useEffect, useState } from 'react';
import { db } from './firebase-config'; // Asume que ya tienes configurado tu archivo firebase-config
import { collection, onSnapshot } from 'firebase/firestore';
import ChatContainer from './fragments/mainContent';
import ClientContainer from './fragments/clientDataFragment';

function App() {
  const [msg, setMsg] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "msg"), (snapshot) => {
      const itemsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMsg(itemsList);
    });
    return () => unsubscribe();
  }, []);

  const handleChatChange = (item) => {
    setCurrentChat(item);
  };

  return (
    <>
      <nav className='messagesNav'>
        <ul className='msgList'>
          {msg.map(item => (
            <li key={item.id}>
              <button onClick={() => handleChatChange(item)}>{item.nombre ? item.nombre : item.id}</button>
            </li>
          ))}
        </ul>
      </nav>
      <ChatContainer numero={currentChat ? currentChat.id : null}/>
      {/* Pasamos currentChat como un array, incluso si es un solo objeto */}
      <ClientContainer numero={[currentChat]}/>
    </>
  );
}

export default App;